.button{
   
  
    width: 10%;
    background: #001;
    color: wheat;
    
}

.button  :hover{
    background : wheat;
}

.div{
    background-color: #000;
}
