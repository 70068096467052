

//====================
// 13. Team Style CSS
// =========================*/




.team-page-area-wrapper{

  margin-right: 20%;
 

  .team-mem-item{
  
    margin-right: 20%;
    margin-bottom: 10%;
  }
  .member-info {
   
    margin-bottom: 20px;
    margin-left: 0%;
    width: 100%;
   
  }
  
}
.team-area-wrapper{

  width: 80%;
  margin-left: 0%;
}
.team-mem-item{
  
  width: 80%;
  margin-left: 170px;
  margin-bottom: 10px;
}
.member-info {

  margin-bottom: 10%;
  margin-left:10%;
  width: 90%;
 
}




//  .team-area{
//     border:  2px solid black;
//     margin-left: 20%;
//     width: 20%;
//   }
@media  screen and (min-width : 1025px){
  .team1{
    display: flex;
    gap: 10%;
  }
  .team-page-area-wrapper{
    border : 2px solid red;
    width : 80%;
    // margin-right: %;
    margin-left: -14%;
   display: flex;
   justify-content: center;

    .pagename{
      margin-left: 40%;
    }

    .team-mem-item{
      border: 2px solid yellow;
      margin-right: 100%;
      padding-left: 20%;
    }
  }
}
// @media only screen and (width : 1024px){
//   .team1{
//     display: flex;
    
//   }
//   .team-page-area-wrapper{
//     border : 2px solid red;
//     width : 80%;
//     // margin-right: %;
//     margin-left: -14%;
//    display: flex;
//    justify-content: center;

//     .pagename{
//       margin-left: 40%;
//     }

//     .team-mem-item{
//       border: 2px solid green;
//       margin-right: 100%;
//       padding-left: 20%;
//     }
//   }
// }
@media screen and (max-width: 765px) {
  .team-page-area-wrapper{
    .member-info {
     
      margin-bottom: 20px;
      margin-left: 50%;
      width: 40%;
     
    }
    .team-area-wrapper{
   
      margin-left: 40px;
      width: 80%;
     
    }
 
    // margin-left: 20px;
    padding: 20px 10px 10px 20px;

    .member-pic {
     
      height: 15vh;
      margin-left: 50%;
      img {
        width: 60%;
      }
    }

    .team-mem-item{
   
      width: 130%;
       margin-left: -160px;
  
    }

     .team-area{
    border:  2px solid black;
    margin-left: -20%;
  }


  }
 
  .team-mem-item{
   
    width: 130%;
     margin-left: -180px;

  }
  .team-area{
   
    margin-left: -20%;
  }
  .member-info {
 
    margin-bottom: 20px;
    margin-left: -55%;
    width: 40%;
   
  }
  .member-pic {
  
    height: 15vh;
    margin-left: 65%;
    img {
      width: 100%;
    }
  }

  .team-area-wrapper{
  
    margin-left: 20px;
    width: 80%;
   
  }
  .member-info {
  
 
  height: 8vh;
  margin-left: 65%;
  margin-bottom: 15%;

 
  
 
 

 

  // .designation {
  //   font-weight: 400;
  //   color: $brandColor;
  // }
}

  
 
 
  }
  @media screen and (min-width: 766px) and (max-width: 820px) {
    .team-page-area-wrapper{
   
    width:  80%;
    margin-left: 10%;
     .member-pic {
        
    height: 18vh;
    margin-left: 40%;
        img {
          width: 100%;
        }
      }


    }
 
 
      .team-mem-item{
       
     
        width: 75%;
        
     
        margin-left: -36px;
    
      }
      // .team-area{
      //   border:  2px solid black;
       
    
      // }
      .member-info {
     
        margin-bottom: 20px;
        margin-left: 50%;
        width: 60%;
       
      }
      .member-pic {
      
    height: 18vh;
    margin-left: 40%;
        img {
          width: 60%;
        }
      }
    
      .team-area-wrapper{
   
       
      }
      .member-info {
        padding-top: 15px;
      
   
      margin-left: 40%;
    
     
      
     
     
    
     
    
      .designation {
        font-weight: 400;
        color: $brandColor;
      }
    }
    
      
     
     
      }
   
  

  
// @media only screen and (min-width : 1024){
//   .team-mem-item{
       
//     border: 2px solid greenyellow;
//     width: 75%;
    
 
//     margin-left: -36px;

//   }
//   .team-area{
//     border:  2px solid black;
   

//   }
//   .member-info {
//     border: 2px solid yellow;
//     margin-bottom: 20px;
//     margin-left: 50%;
//     width: 50%;
   
//   }
//   .member-pic {
//     border: 2px solid  blue;
//     margin-left: 50%;
//     img {
//       width: 20%;
//     }
//   }

//   .team-area-wrapper{
//     border: 2px solid black;
   
//   }
//   .member-info {
  
//   border: 2px solid red;
//   margin-left: 240px;

 
  
 
 

 

//   .designation {
//     font-weight: 400;
//     color: $brandColor;
//   }
// }


// }
 

// .team-wrapper{
//   display: flex;
//   border: 2px solid;
// }

// .team-mem-item {
//   transition: 0.4s;
//   border: 2px solid green;
//   width: 100%;
//   height: 100%;
//   margin-left: -40%;

//   .member-info {
//     text-align: center;
//     border: 2px solid red;
//     width: 50%;
//     margin-left: 60%;
    
//     background-color: $white;
//     padding: 15px 10px;

//     h5 {
//       font-weight: 600;
//       font-size: 18px;
//       line-height: 1;

//       a {
//         color: $headingColor;

//         &:hover {
//           color: $hvrColor;
//         }
//       }
//     }

//     .designation {
//       font-weight: 400;
//       color: $brandColor;
//     }
//   }

 
//   .member-pic {
//     border: 2px solid gray;
//     width: 50%;
//     margin-left: 60%;
//     img {
//       width: 100%;
//     }
//   }

//   &--2 {
//     position: relative;
//     margin-top: 40px;

//     .member-info {
     
//       background-color: transparent;
//       padding: 0;
//       position: absolute;
//       top: -15px;
//       left: -110px;
//       width: 100%;
//       @include hide;
//       transform: translateY(-20px);

//       // @media #{$sm-device} {
//       //   left: -80px;
//       // }

//       &:after{
//         background-image: url('../../img/team/team-arrow.png');
//         background-repeat: no-repeat;
//         background-position: center center;
//         content: '';
//         position: absolute;
//         transform: translateY(-10px);
//         transition: 0.4s;
//         transition-duration: 0.8s;
//         top: 100%;
//         right: -15px;
//         height: 40px;
//         width: 100%;
//       }
//     }

//     .member-pic {
//       text-align: center;
//       border: 2px solid red;

//       img {
//         max-height: 400px;
//         width: auto;
//         margin: auto;
//       }
//     }

//     &:hover{
//       .member-info{
//         @include show;
//         transform: none;

//         &:after{
//           transform: none;
//         }
//       }
//     }
//   }
// }

// .team-area-wrapper {
//   border: 2px solid black;
//   display: flex;
//   flex-wrap: wrap;
//   height: 20%;

//   .section-title {
//     @media screen and (min-width: 1500px) {
//       padding-right: 100px;
//     }
//   }

//   .team-content-wrap {
//     @media screen and (min-width: 1500px) {
//       padding-left: 45px;
//     }

//     .slick-dots {
//       display: flex!important;
//       justify-content: center;
//     }

//     .slick-list {
//       margin: 0 -15px;
//       @media #{$sm-device} {
//         margin: 0 -10px;
//       }

//       .slick-slide {
//         padding: 0 15px;

//         @media #{$sm-device} {
//           padding: 0 10px;
//         }
//       }
//     }
//   }
// }

// .team-area{
//   border: 2px solid red;;
//   &-left{
//     background-color: $brandColor;
//     padding: 110px 100px 105px;
//     height: 100%;

//     @media #{$xlmax-device} {
//       padding: 90px 25px;
//     }

//     @media #{$md-device} {
//       padding: 90px;
//     }

//     @media #{$sm-device} {
//       padding: 60px 30px  50px;
//     }

//     @media #{$xs-device} {
//       padding: 60px 15px 50px;
//     }
//   }

//   &-right{
//     position: relative;
//     padding: 110px 100px 0;

//     @media #{$md-device} {
//       padding: 80px 100px 0;
//     }

//     @media #{$sm-device} {
//       padding: 50px 30px 0;
//     }

//     &--2{
//       &:before{
//         @include overlay($white, 0.85);
//       }
//     }
//   }
// }


// Team Page
// .team-page-area-wrapper{
//   .team-mem-item{
//     margin-top: 30px;
//     transition: 0.4s;

//     &:hover{
//       transform: translateY(-10px);
      
//     }
//   }
// }

