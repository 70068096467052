/* @media only screen and (width : 1200){
    .nav-menu{
        display: none;
    }
    .ham-icon{
        display: block;
        color: #fff;
       
  
        border: 2px solid red;
        margin-right: 40px;
        margin-bottom: 10px;
    }

} */

@media only screen and (width: 768px) {

    /* .header-area{
        height: 60vh;
    } */

    header{
        position: sticky; 
      
        top:100; 
        z-index: 999;
        width: 100%;
        height: 8vh; 
   
      
        box-shadow: 0px 5px 10px 0 white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        background-color: white;
    }

    .image-wrapper{
       
       
        
        margin-top: 20px;
        
        width: 40%;
    }
    .image-wrapper img{
        width: 230px;
    }
    .nav-menu{
        display: none;
    }
    .ham-icon{
        display: block;
        color: #fff;
      
       
  
       
        margin-right: 80px;
        margin-bottom: 10px;
        margin-right: 40px;
        
        margin-bottom: 10px;
    }
    
    .mobile-nav-menu{
      
   
        position: absolute;
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top:80px;
        left:-100;
        width: 100%;
     
        
        padding: 10px;
        height: 400px;
        gap: 30px;
        animation: slideDown 1s ease;
        transition: all 1s ease;
    }
    .mobile-nav-menu span svg{
        size: 25px;
    }

    .mobile-nav-menu span{
        font-size: 25px;
    }
  }

@media only screen and (width: 1024px) {

    /* .header-area{
        height: 60vh;
    } */

    header{
        position: sticky; 
       
        top:100; 
        z-index: 999;
        width: 100%;
        height: 8vh; 
   
      
        box-shadow: 0px 5px 10px 0 white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        background-color: white;
    }

    .image-wrapper{
    
        
        margin-top: 10px;
        
        width: 100%;
    }
    .image-wrapper img{
        width: 230px;
    }
    .nav-menu{
        display: none;
    }
    .ham-icon{
        display: block;
        color: #fff;
       
  
       
        margin-right: 40px;
        margin-bottom: 10px;
    }
    
    .mobile-nav-menu{
   
        
    
       
   
        position: absolute;
        padding: 40px;
        height: 400px;
        gap: 20px;
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top:80px;
        left:-100;
        width: 100%;
       
        
       
       
        gap: 30px;
        animation: slideDown 1s ease;
        transition: all 1s ease; 
    }
    .mobile-nav-menu span svg{
        size: 25px;
    }

    .mobile-nav-menu span{
        font-size: 25px;
    }
  }


@media only screen and (width: 820px) {

    /* .header-area{
        height: 60vh;
    } */

    header{
        position: sticky; 
      
        top:100; 
        z-index: 999;
        width: 100%;
        height: 8vh; 
   
      
        box-shadow: 0px 5px 10px 0 white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        background-color: white;
    }

    .image-wrapper{
      
        
        margin-top: 20px;
        
        width: 50%;
    }
    .image-wrapper img{
        width: 230px;
    }
    .nav-menu{
        display: none;
    }
    .ham-icon{
        display: block;
        color: #fff;
       
  
       
        margin-right: 40px;
        margin-bottom: 10px;
    }
    
    .mobile-nav-menu{
    
   
        position: absolute;
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top:80px;
        left:-100;
        width: 100%;
        background-color: black;
        
        padding: 10px;
        height: 350px;
        gap: 30px;
        animation: slideDown 1s ease;
        transition: all 1s ease;
    }
    .mobile-nav-menu span svg{
        size: 25px;
    }

    .mobile-nav-menu span{
        font-size: 25px;
    }
  }


  /* keyframes */

  @keyframes slideDown {
    0%{
        transform: translateY(-500px);
    }

    100%{
        transform: translateY(0);
    }
  }

  @media screen and (min-width: 1025px) {

   

    /* header{
        position: sticky; 
        border: 2px solid yellow;
        top:200; 
        z-index: 999;
        width: 100%;
        height: 2vh; 
   
      
        box-shadow: 0px 5px 10px 0 white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        background-color: white;
    } */

    /* .image-wrapper{
        border: 2px solid red;
        
        margin-top: 30px;
        margin-left: 10px;
        width: 60%;
    } */
    .image-wrapper img{
        width: 230px;
    }
    .nav-menu{
        display: block;
    }
    .ham-icon{
        display: none;
        color: #fff;
     
        margin-right: 40px;
        
        margin-bottom: 10px;
    }
    
    /* .mobile-nav-menu{
       
   
        position: absolute;
        
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top:80px;
        left:-100;
        width: 100%;
        background-color: black;
        
        padding: 10px;
        height: 350px;
        gap: 30px;
        animation: slideDown 1s ease;
        transition: all 1s ease;
    }
    .mobile-nav-menu span svg{
        size: 20px;
    }

    .mobile-nav-menu span{
        font-size: 25px;
    } */
  }


  /* keyframes */

  @keyframes slideDown {
    0%{
        transform: translateY(-500px);
    }

    100%{
        transform: translateY(0);
    }
  }

@media screen and (max-width: 415px) {

    .header-area{
        height: 6vh;
    }

    header{
        position: sticky; 
     
        top:200; 
        z-index: 999;
        width: 100%;
        height: 8vh; 
   
      
        box-shadow: 0px 5px 10px 0 white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        background-color: white;
    }

    .image-wrapper{
      
        
        margin-top: 30px;
        margin-left: 10px;
        width: 60%;
    }
    .image-wrapper img{
        width: 230px;
    }
    .nav-menu{
        display: none;
    }
    .ham-icon{
        display: block;
        color: #fff;
      
        margin-right: 40px;
        
        margin-bottom: 10px;
    }
    
    .mobile-nav-menu{
       
   
        position: absolute;
        
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top:80px;
        left:-100;
        width: 100%;
        background-color: black;
        
        padding: 10px;
        height: 350px;
        gap: 30px;
        animation: slideDown 1s ease;
        transition: all 1s ease;
    }
    .mobile-nav-menu span svg{
        size: 20px;
    }

    .mobile-nav-menu span{
        font-size: 25px;
    }
  }


  /* keyframes */

  @keyframes slideDown {
    0%{
        transform: translateY(-500px);
    }

    100%{
        transform: translateY(0);
    }
  }